<div class="img" *ngIf="reInit" [formGroup]="group">
    <div class="title">
        <h2 class="padding-10">
            {{ field.name | titlecase }}
        </h2>
    </div>

    <div style="position: relative; width: 100%;">
        <input
            style="width: 100%;height: 50px;margin-bottom: 15px;border: 1px solid #c6c4c4;border-radius: 5px; padding-left: 5px;"
            type="text" placeholder="{{ field.name | titlecase }}" (input)="onSearch($event.target.value)" />
        <mat-icon style="position: absolute;right: 10px;top:40%;transform: translateY(-50%);cursor: pointer;">search
        </mat-icon>
    </div>

    <div fxLayoutAlign="center center" *ngIf="filteredImages.length === 0" style="font-size: 20px;">
        No records found
    </div>

    <!-- <div class="image-container pointer" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center" *ngIf="filteredImages.length > 0">
      <ng-container *ngFor="let image of filteredImages">
        <img (click)="setValue(image.value)" 
        class="icon" 
        fxFlex.xs="50" 
        fxFlex="33.33" 
        fxFlexAlign="stretch" 
        [alt]="field.customfield3" 
        [src]="serverPath + image.image">
      </ng-container>
    </div> -->

    <div class="pointer" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center"
        *ngIf="filteredImages.length > 0">
        <div fxLayout="row wrap" fxFlex="100" class="gapbottom" fxLayoutAlign="space-between center">
            <div fxFlex.xs="50" fxFlex="25" *ngFor="let image of filteredImages">
                <img style="width: 100%;" (click)="setValue(image.value)" [alt]="field.customfield3"
                    [src]="serverPath + image.image">
            </div>

        </div>

    </div>
</div>